<template>
  <div class="starry-background">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "StarryTravel",
  data() {
    return {
      stars: [], // 存储星星信息
      starCount: 200, // 星星数量
      centerX: 0, // 画布中心点X
      centerY: 0, // 画布中心点Y
    };
  },
  mounted() {
    this.initCanvas();
    window.addEventListener("resize", this.initCanvas);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initCanvas);
  },
  methods: {
    initCanvas() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      this.centerX = canvas.width / 2;
      this.centerY = canvas.height / 2;

      // 初始化星星数据
      this.stars = Array.from({ length: this.starCount }, () => ({
        x: Math.random() * canvas.width - this.centerX,
        y: Math.random() * canvas.height - this.centerY,
        z: Math.random() * canvas.width,
        speed: Math.random() * 1 + 2, // 星星移动速度
        color: this.getRandomColor(), // 随机颜色
      }));

      this.animateStars(ctx, canvas);
    },
    animateStars(ctx, canvas) {
      const draw = () => {
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        this.stars.forEach((star) => {
          // 计算星星位置
          star.z -= star.speed;
          if (star.z <= 0) star.z = canvas.width;

          const scale = canvas.width / star.z;
          const x = star.x * scale + this.centerX;
          const y = star.y * scale + this.centerY;

          const radius = Math.max(0, scale); // 星星大小随距离缩放
          ctx.beginPath();
          ctx.arc(x, y, radius, 0, Math.PI * 2);
          ctx.fillStyle = star.color;
          ctx.fill();
        });

        requestAnimationFrame(draw);
      };

      draw();
    },
    getRandomColor() {
      // 生成随机颜色
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      return `rgb(${r},${g},${b})`;
    },
  },
};
</script>

<style scoped>
.starry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}
canvas {
  display: block;
}
</style>
