<template>
  <div class="container mx-auto p-4">
    <StarryBackground></StarryBackground>
    <div class="shadow-md rounded-lg p-6 mb-8 " style="    background: #909ca317;">
      <h3 class="text-sm font-semibold mb-2 flex items-center text-white">
            <LockIcon class="mr-1 h-4 w-4" />
            定期存款(USDT)
          </h3>
          <div class="grid md:grid-cols-1 gap-8">
              <div class="flex justify-center">
                <p class="mb-4 text-black text-right" ><strong> <span class="text-green-500 text-5xl">{{ Number(usdtBalance /1e6).toFixed(2) }} <span class="text-sm">USDT</span> </span></strong></p>
              </div>  
          </div>
      
      <div class="mb-4 p-4 rounded-md" style="background-color:#dbeafe05">
        <p v-if="timeUntilNextWithdrawal === 0">
          您现在可以提款！
        </p>
        <p v-else class="text-xs font-semibold mb-2 flex items-center  text-white ">
            <span class="text-right">解锁倒计时：{{ formatTime(timeUntilNextWithdrawal) }}</span>
        </p>
      </div>
      <!-- <button 
        @click="handleWithdraw" 
        :disabled="timeUntilNextWithdrawal > 0"
        class="bg-blue-500 text-white  px-4 py-3 rounded-md hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
      >
        提款
      </button> -->
      <div class="flex items-center space-x-2">
        <input
          type="text"
          v-model="wAmount"
          placeholder="输入提取数量"
          class="flex-grow px-3 py-2 bg-white border border-gray-700 rounded text-black"
        /><br>
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="handleWithdraw" 
          :disabled="timeUntilNextWithdrawal > 0"
            class="copyBtn w-full mt-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 disabled:bg-slate-400 text-white rounded-md transition-colors duration-300"
          >
          资金提取
        </button>
      </div>
    </div>

    <!-- <div class="bg-slate-700 shadow-md rounded-lg p-6">
      <h2 class="text-sm font-bold mb-4 text-white">提款历史</h2>
      <table class="w-full text-white">
        <thead>
          <tr>
            <th class="text-left text-sm">金额 (USDT)</th>
            <th class="text-left text-sm">时间</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(event, index) in withdrawalEvents" :key="index">
            <td class="text-sm">{{ event.amount }}</td>
            <td class="text-sm">{{ event.timestamp }}</td>
          </tr>
        </tbody>
      </table>
    </div> -->
  </div>
  
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import web3 from './web3';
import StarryBackground from './components/stars.vue'
import { Wallet, CreditCard, Gift, Loader2, LockIcon, Gem } from "lucide-vue-next";
const contractAddress = "0x41Aa411C2aECC7C9a2DDb64484a45F453E211a42"
const timeUntilNextWithdrawal = ref(0) // 1小时（以秒为单位）
const usdtBalance = ref(0) // 1小时（以秒为单位）
const withdrawalEvents = ref([
  
])

const wAmount = ref('')
const handleWithdraw = async () => {
  if(wAmount.value == 0){
    alert("金额必须大于0")
    return
  }
  if(Number(usdtBalance.value) == 0){
    alert("无可提取余额")
    return;
  }
  if(Number(wAmount.value) > Number(usdtBalance.value)){
    alert("最大提取"+usdtBalance.value+"U")
    return
  }
  await web3.withdrawMoney(wAmount.value).catch(err=>{
    console.log(err)
  })
  if (timeUntilNextWithdrawal.value === 0) {
    timeUntilNextWithdrawal.value = await web3.timeUntilNextWithdrawal()
  }
}

const formatTime = (seconds) => {
  const days = Math.floor(seconds / 86400); // 1天 = 86400秒
  const hours = Math.floor((seconds % 86400) / 3600); // 剩余的小时
  const minutes = Math.floor((seconds % 3600) / 60); // 剩余的分钟
  const remainingSeconds = seconds % 60; // 剩余的秒数
  return `${days}天 ${hours}小时 ${minutes}分钟 ${remainingSeconds}秒`;
};

let countdownInterval

onMounted(async () => {
  await web3.initWeb3();
  timeUntilNextWithdrawal.value = await web3.timeUntilNextWithdrawal();
  // withdrawalEvents.value = await web3.withdrawList().catch(err=>{});
  usdtBalance.value = await web3.usdtBalance();
  countdownInterval = setInterval(() => {
    if (timeUntilNextWithdrawal.value > 0) {
      timeUntilNextWithdrawal.value--
    }
  }, 1000)
})

onUnmounted(() => {
  if (countdownInterval) {
    clearInterval(countdownInterval)
  }
})
</script>