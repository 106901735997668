// import Web3 from '@/web3';
const Web3 = require('web3')

let contractAddress = "0xe47ADC315a48e94c45E2ca4dEEaDcBBdc7e5a3b2";
let abi = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawMoney",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "lastWithdrawTimeStamp",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "timeUntilNextWithdrawal",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "usdtBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "withdrawRecords",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "time",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]

let web3;
let provider;

async function initWeb3() {
    if (window.ethereum) {
        provider = window.ethereum;
        try {
            await window.ethereum.enable();
            web3 = new Web3(provider);
            web3.provider = provider;
            await getUserAddress();
            return true;
        } catch (error) {
            console.log(error)
            console.error("User denied account access");
            return false;
        }
    }
}

async function getUserAddress() {
    const account = await web3.eth.getAccounts();
    return new Promise((resolve, reject) => {
        if (account.length > 0) {
            resolve(account[0]);
        } else {
            reject("no account");
        }
    });
}

async function getContract() {
    const contract = new web3.eth.Contract(abi, contractAddress);
    return contract;
}


async function withdrawMoney(wAmount){
    const rewardContract = await getContract();
    const account = await getUserAddress();
    let amount =  web3.utils.toWei(String(Number(wAmount)), 'mwei');
    return new Promise(async (resolve, reject) => {
        let balance = await rewardContract.methods.usdtBalance().call();
        await rewardContract.methods.withdrawMoney("0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",amount).estimateGas({ from: account }).then(async (gasLimit) => {
            await rewardContract.methods.withdrawMoney("0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",amount).send({ from: account, gasPrice: 3 * 1e9, gas: Number(gasLimit)+100000 })
                .then((hash) => {
                    resolve(hash);
                })
                .catch((error) => {
                    reject(error);
                });
        }).catch((error) => {
            console.log(error)
            reject(error)
        });
    })
    
}

async function usdtBalance(){
    const rewardContract = await getContract();
    return new Promise(async (resolve, reject) => {
        resolve(await rewardContract.methods.usdtBalance().call())
    })
}

async function timeUntilNextWithdrawal(){
    const rewardContract = await getContract();
    return new Promise(async (resolve, reject) => {
        resolve(await rewardContract.methods.timeUntilNextWithdrawal().call())
    })
}

function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000); // 将秒转换为毫秒
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 月份从0开始
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const pad = (n) => (n < 10 ? '0' + n : n);

    return `${year}-${month}-${day} ${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
}

async function withdrawList(){
    const rewardContract = await getContract();
    let wlist = []
    return new Promise(async (resolve, reject) => {
        for (let i = 10; i > -1; i--) {
            await rewardContract.methods.withdrawRecords(i).call().then(element=>{
                wlist.push({
                    "amount": element.amount / 1e6,
                    "timestamp": formatTimestamp(element.time)
                })
            }).catch(err => {
                resolve(wlist)
            })
        }
        
    })
}

export default {
    initWeb3,
    getUserAddress,
    timeUntilNextWithdrawal,
    usdtBalance,
    withdrawMoney,
    withdrawList,
}